import React, { FunctionComponent, useEffect, useState } from "react";
import { useLazyQuery } from '@apollo/client';
import parse from "html-react-parser";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import queryString from 'query-string';

import { GET_POST } from '../../graphql/posts/get-post';
import SEO from "../../components/seo/seo"
import Loading from "../../components/loading/loading";
import PostStyle from "../../styles/post-style";
import Author from "../../components/author/author";
import Categories from "../../components/categories/categories";
import Tags from "../../components/tags/tags";


const BlogPostPage:  FunctionComponent = () => {
  const parsed = queryString.parse(location.search);
  const id = parsed["id"];
  const [ post, setPost ] = useState<any>();

  useEffect(() => {
    if (!id) window.open('/blog', '_self');
    else {
      getPost();
    }
  }, [id])

  const [getPost, { loading, data: postData, error }] = useLazyQuery(GET_POST, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!loading && postData) {
      if (postData.post) {
        setPost(postData.post);
      } else {
        window.open('/404', '_self');
      }
    }
  }, [loading, postData]);

  useEffect(() => {
    if (!loading && error) {
      console.log('Video Post Error: ', error);
      window.open('/404', '_self');
    }
  })

  return (
    <PostStyle>
      {loading && !error ? (
        <Loading />
      ) : (
        <>
          { post && <SEO title={post?.title} /> }
          { post && post.featuredImage ? (
            <>
              <div className="container p-0">
                <div
                  style={{
                    backgroundImage: `url(${post.featuredImage.node.sourceUrl})`
                  }}
                  className="hero-image" 
                />
              </div>
            </>
          ) : null }
          { post && (
            <div className="container mt-4 mb-5">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">
                  Home
                </Link>
                <Link color="inherit" href="/blog">
                  Blogs
                </Link>
              </Breadcrumbs>
              <div className="row mt-3">
                <div className="col">
                  <Typography variant="h1" gutterBottom>{ post.title }</Typography>
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <Tags post={post} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div>
                    {parse(`${post.content}`)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Categories post={post} />
                </div>
              </div>
              <Author post={post} />
            </div>
          )}
        </>
      )}
    </PostStyle>
  )
}

export default BlogPostPage
